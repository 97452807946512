import React from "react"
import SEO from "../components/seo"
import CarouselHero from "../components/carouselHero"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { FaWhatsapp, FaRegEnvelope, FaInstagram } from "react-icons/fa"

const IndexPage = ({ data }) => {
  return (
    <React.Fragment>
      <SEO title="Your Wedding Website Solution" />
      <CarouselHero />
      <div className="max-w-6xl text-center mx-auto py-20 px-4">
        <p className="text-lg text-gray-800 font-body text-3xl font-thin">
          Sawir Studio designs and craft your wedding website invitation from
          Indonesia.
        </p>
        <div className="py-2" />
        <a
          href="https://wa.me/628888344735?text=Halo%20Sawir%20Studio%2C%28please%20tell%20your%20story%29"
          target="_blank"
          rel="noopener noreferrer"
          className="block font-body"
        >
          <button className="bg-gold px-5 py-2 text-md text-white rounded shadow-md hover:bg-goldDarker hover:shadow-sm w-full md:w-auto text-lg">
            Let's have a chat via Whatsapp{" "}
            <FaWhatsapp className="inline text-white text-4xl" />
          </button>
        </a>
        <div className="my-12 md:my-6" />
        <a
          href="mailto:sawir.ricardo@gmail.com?subject=Hi, Sawir Studio. Interested with your wedding website services&body=Hi, Sawir Studio. My name is (insert your name). I'm interested to work with you to make my wedding awesome.(You can elaborate from here...)"
          target="_blank"
          rel="noopener noreferrer"
          className="block text-gold text-md hover:text-goldDarker font-body text-lg"
        >
          Prefer to contact us via email{" "}
          <FaRegEnvelope className="inline text-gold text-xl" /> ? Click here.
        </a>
      </div>
      <div className="container mx-auto font-body">
        <div className="px-5">
          <div className="flex flex-wrap md:-mx-5 my-20">
            <div className="w-full md:w-1/2 md:px-5">
              <Img
                fluid={data.whyDoWeNeedWeddingWebsite.childImageSharp.fluid}
                alt="A couple seeing their wedding website on their laptop and tablet"
              />
            </div>
            <div className="w-full md:w-1/2 md:px-5 my-auto">
              <div className="text-gray-800 mt-4 md:m-0">
                <h2 className="text-goldDarker text-4xl font-light">
                  Why do we need a wedding website?
                </h2>
                <p className="text-lg my-4">
                  We, at Sawir Studio wants to help couples who are planning for
                  their once in their lifetime celebration. We know how
                  complicated and problematic it is, planning one of the biggest
                  celebration in our life.
                </p>
                <p className="text-lg">
                  With making your wedding website, you will be able to announce
                  your wedding day to either your friends, or even your besties
                  or your cousins with ease.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="px-5">
          <div className="flex flex-wrap md:-mx-5 my-20">
            <div className="w-full md:w-1/2 md:px-5">
              <Img
                fluid={data.oneClick.childImageSharp.fluid}
                alt="a young hand female clicking her phone to share her wedding website"
              />
            </div>
            <div className="w-full md:w-1/2 md:px-5 my-auto">
              <div className="text-gray-800 mt-4  md:m-0">
                <h2 className="text-goldDarker text-4xl font-light">
                  Announce your wedding day in 1 click.
                </h2>
                <p className="text-lg my-4">
                  With your wedding website, you only need to click the share
                  button and share with those people you want to come. You can
                  share it with either your Whatsapp private message, Line Chat,
                  Instagram Direct Message, or your group.
                </p>
                <p className="text-lg">
                  No need to make special appointment with your 1,079 friends to
                  invite them. No need to ask your 571 friends’ addresses to
                  send your invitations. Just send to their online contacts and
                  you’re done.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="px-5">
          <div className="flex flex-wrap md:-mx-5 my-20">
            <div className="w-full md:w-1/2 md:px-5">
              <Img
                fluid={data.rsvp.childImageSharp.fluid}
                alt="A woman who are doing RSVP on her wedding website while checking her guest data"
              />
            </div>
            <div className="w-full md:w-1/2 md:px-5 my-auto">
              <div className="text-gray-800 mt-4  md:m-0">
                <h2 className="text-goldDarker text-4xl font-light">
                  Know the number of your guests, literally.
                </h2>
                <p className="text-lg my-4">
                  We provide you with an RSVP form to let you know who will and
                  who will not come to your wedding. We know how stressed it is
                  to guess the number of the guests since one of the biggest
                  spending in a wedding celebration is the food’s spending. It’s
                  not even funny when your guests are fighting over food, right?
                </p>
                <p className="text-lg">
                  Now, with wedding website, you can get the number of your
                  guests and provide the the right amount of food.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="px-5">
          <div className="flex flex-wrap md:-mx-5 my-20">
            <div className="w-full md:w-1/2 md:px-5">
              <Img
                fluid={data.design.childImageSharp.fluid}
                alt="A view of designer's desk with mannequin, colors palletes, a mug of coffee, a keyboard, a graphic tablet, and a monitor."
              />
            </div>
            <div className="w-full md:w-1/2 md:px-5 my-auto">
              <div className="text-gray-800 mt-4  md:m-0">
                <h2 className="text-goldDarker text-4xl font-light">
                  Wedding website designed for your taste.
                </h2>
                <p className="text-lg my-4">
                  At Sawir Studio, we not only provide good design, but also
                  provide designs that match with our clients’ taste. Everyone
                  has their own definition of their “good-looking” design, and
                  that’s where we aim for with your wedding website.
                </p>
                <p className="text-lg">
                  We will work together in coming up with the design of your
                  wedding website that match with your taste.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="px-5">
          <div className="flex flex-wrap md:-mx-5 my-20">
            <div className="w-full md:w-1/2 md:px-5">
              <Img
                fluid={data.shareDirection.childImageSharp.fluid}
                alt="a young female hand sharing the direction to her wedding venue with Google Maps application"
              />
            </div>
            <div className="w-full md:w-1/2 md:px-5 my-auto">
              <div className="text-gray-800 mt-4  md:m-0">
                <h2 className="text-goldDarker text-4xl font-light">
                  Share not only your wedding venues, but also the direction and
                  the agenda.
                </h2>
                <p className="text-lg my-4">
                  Your wedding website can share the direction, integrated with
                  maps application in your guests’ phone. Your guests can also
                  save the date and the agenda details on their phones.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="px-5">
          <div className="flex flex-wrap md:-mx-5 my-20">
            <div className="w-full md:w-1/2 md:px-5">
              <Img
                fluid={data.album.childImageSharp.fluid}
                alt="a couple browsing their wedding website to see their wedding gallery."
              />
            </div>
            <div className="w-full md:w-1/2 md:px-5 my-auto">
              <div className="text-gray-800 mt-4  md:m-0">
                <h2 className="text-goldDarker text-4xl font-light">
                  Transform your wedding website into your online wedding album.
                </h2>
                <p className="text-lg my-4">
                  You can still use your wedding website not only before the
                  day, but also after the day. Your wedding website can be used
                  to save your once in your lifetime celebration moments until
                  death do you apart. (It’s serious!)
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="my-10" />
      <div className="py-10 container mx-auto text-center px-4">
        <Img fixed={data.divider.childImageSharp.fixed} />
        <h2 className="text-gold text-6xl font-logo">
          What are your waiting for ?
        </h2>
        <p className="text-goldDarker font-body text-lg my-6">
          Get a FREE consultation with us to talk about what we can do together
          to prepare your big day!
        </p>
        <div className="py-2" />
        <a
          href="https://wa.me/628888344735?text=Halo%20Sawir%20Studio%2C%28please%20tell%20your%20story%29"
          target="_blank"
          rel="noopener noreferrer"
          className="block font-body"
        >
          <button className="bg-gold px-5 py-2 text-md text-white rounded shadow-md hover:bg-goldDarker hover:shadow-sm w-full md:w-auto text-lg">
            Let's have a chat via Whatsapp{" "}
            <FaWhatsapp className="inline text-white text-4xl" />
          </button>
        </a>
        <div className="my-12 md:my-6" />
        <a
          href="mailto:sawir.ricardo@gmail.com?subject=Hi, Sawir Studio. Interested with your wedding website services&body=Hi, Sawir Studio. My name is (insert your name). I'm interested to work with you to make my wedding awesome.(You can elaborate from here...)"
          target="_blank"
          rel="noopener noreferrer"
          className="block text-gold text-md hover:text-goldDarker font-body text-lg"
        >
          Prefer to contact us via email{" "}
          <FaRegEnvelope className="inline text-gold text-xl" /> ? Click here.
        </a>
      </div>
      <div className="bg-gold py-12 text-center text-white font-body">
        <a
          href="https://instagram.com/sawirstudioid"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaInstagram className="inline-block text-4xl text-white hover:text-gray-300" />
        </a>
        <div className="my-6" />
        <p>ⓒ{new Date().getFullYear().toString()} Sawir Studio</p>
        <p>
          Made with{" "}
          <span role="img" aria-label="love">
            ❤
          </span>
          ️ by{" "}
          <a
            href="https://sawirstudio.com"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-gray-200"
          >
            Ricardo Sawir at Sawir Studio
          </a>
        </p>
      </div>
    </React.Fragment>
  )
}
export default IndexPage

export const query = graphql`
  {
    whyDoWeNeedWeddingWebsite: file(
      relativePath: { eq: "features/KVsmall.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    oneClick: file(relativePath: { eq: "features/505small.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    rsvp: file(relativePath: { eq: "features/89559small.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    design: file(relativePath: { eq: "features/1341small.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    shareDirection: file(relativePath: { eq: "features/directionsmall.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    album: file(relativePath: { eq: "features/16786small.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    divider: file(relativePath: { eq: "divider.png" }) {
      childImageSharp {
        fixed(width: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
