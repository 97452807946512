import React from "react"
import { Carousel } from "react-responsive-carousel"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Typist from "react-typist"

const CarouselHero = () => {
  const { images } = useStaticQuery(graphql`
    {
      images: allFile(filter: { relativeDirectory: { eq: "carousel" } }) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  return (
    <React.Fragment>
      <div className="relative w-full">
        <Carousel
          showThumbs={false}
          infiniteLoop
          autoPlay
          showStatus={false}
          showArrows={false}
          showIndicators={false}
          transitionTime={1000}
        >
          {images.edges.map((image, index) => {
            return (
              <Img
                key={`${index}`}
                className="absolute inset-0 h-full"
                style={{ height: "80vh" }}
                fluid={image.node.childImageSharp.fluid}
                alt="Couple in a wedding"
              />
            )
          })}
        </Carousel>
        <div
          className="absolute inset-0"
          style={{ backgroundColor: "rgba(0,0,0,0.3)" }}
        />
        <div className="absolute inset-0 text-white font-thin container mx-auto text-center">
          <div style={{ height: "20vh" }} />
          <h1 className="font-logo  text-5xl">&lt;SawirStudio/&gt;</h1>
          <h2 className="text-3xl md:text-5xl font-body">
            Announce your wedding day with your{" "}
            <div className="font-headline text-5xl md:text-6xl">
              <Typist>
                <span>gorgeous</span>
                <Typist.Backspace count={8} delay={2000} />
                <span>memorable</span>
                <Typist.Backspace count={9} delay={2000} />
                <span>thoughtful</span>
                <Typist.Backspace count={10} delay={2000} />
                <span>everlasting</span>
              </Typist>
            </div>{" "}
            wedding website
          </h2>
        </div>
      </div>
    </React.Fragment>
  )
}

export default CarouselHero
